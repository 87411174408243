<template>
  <div>
    <h1>Klant #{{ routeKlantId }}</h1>

    <h2>Boekingen</h2>
    <small class="block mb-4">Gesorteerd op aanmaak datum, nieuwste als eerste</small>
    <UITableRitten
      :type="apiData.type"
      hide-bevestig
      hide-bewerken
      hide-annuleer
      v-bind="$attrs"
      :list="loading || !data ? [] : data.list"
      @refresh="getData"
      class="mt-4"
    />
    <UITablePagination v-if="data" class="mt-2" :count="data.count" v-model:limit="apiData.limit" v-model:offset="apiData.offset" />
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'

import useGetApi from '@/hooks/useGetApi'
import UITableRitten from '@/components/Table/Ritten/Index.vue'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const route = useRoute()
const routeKlantId = computed(() => Number(route.params.klant_id))

const apiData = ref({
  type: 'ALLES',
  limit: 20,
  offset: 0,
})

const apiDataMerged = computed(() => ({
  ...apiData.value,
  klant_id: routeKlantId.value,
}))

const { data, getData, /* reset, cancel,*/ loading } = useGetApi('/api/dashboard/ritten/list', apiDataMerged, { watch: true })
</script>

